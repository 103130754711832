import { Button, Checkbox, Dialog, FormControlLabel, IconButton, MenuItem, Select, TextField } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { useState } from 'react';
import { useNotify } from 'react-admin';
import { Controller, FieldPath, SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import { BiEdit, BiTrash } from 'react-icons/bi';
import constants from '../../constants';
import { IPrivateService, LocaleStringType } from '../../types';
import { getLocalizedErrorMessage } from '../../utils/functions';

type ServiceTypes = '1TO1' | 'HELPING_HAND' | 'SAFE_SPACE' | 'SAFE_SPACE_TRIAL';

const TypesNames: Record<ServiceTypes, string> = {
    '1TO1': 'אחד על אחד',
    HELPING_HAND: 'עזרה ראשונה',
    SAFE_SPACE: 'מרחב בטוח',
    SAFE_SPACE_TRIAL: 'מרחב בטוח - היכרות',
};

const DESCRIPTION_LIMIT = 200;
const TITLE_LIMIT = 60;

type FormData = {
    title: LocaleStringType;
    description: LocaleStringType;
    duration: number;
    price: number;
    price_before_discount?: number;
    gap_duration?: number;
    public: boolean;
    type: ServiceTypes;
};

const PrivateServiceItem = ({
    service,
    refetch,
    openDialog,
    openDeleteConfirmation,
}: {
    service: IPrivateService;
    refetch: () => void;
    openDialog: (service: IPrivateService) => void;
    openDeleteConfirmation: (service: IPrivateService) => void;
}) => (
    <div className='w-full border-b py-2 px-4 flex flex-row justify-between hover:bg-gradient-to-l from-gray-100 to-white transition-colors'>
        <span>
            <h1 className='font-semibold text-start'>{service.title?.he}</h1>
            <span className='text-sm flex-row gap-4 flex'>
                <ServiceDetail label={TypesNames[service.type]} width='w-32' />
                <Divider />
                <ServiceDetail label={`${service.price} ₪`} width='w-12' />
                <Divider />
                <ServiceDetail label={`${service.duration} דקות`} width='w-16' />
                <Divider />
                <ServiceDetail label={service.public ? 'ציבורי' : 'מנויים'} width='w-16' />
            </span>
        </span>
        <ActionButtons openDialog={() => openDialog(service)} openDeleteConfirmation={() => openDeleteConfirmation(service)} />
    </div>
);

const ServiceDetail = ({ label, width }: { label: string; width: string }) => <span className={`${width} text-start`}>{label}</span>;

const Divider = () => <span className='border-s border-dotted border-black my-1' />;

const ActionButtons = ({ openDialog, openDeleteConfirmation }: { openDialog: () => void; openDeleteConfirmation: () => void }) => (
    <span className='flex flex-row gap-8'>
        <IconButton color='info' onClick={openDialog}>
            <BiEdit />
        </IconButton>
        <IconButton color='error' onClick={openDeleteConfirmation}>
            <BiTrash />
        </IconButton>
    </span>
);

const PrivateServicesTable = ({ privateServices = [], expert_id, refetch }: { privateServices?: IPrivateService[]; expert_id: string; refetch: () => void }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
    const [serviceToEdit, setServiceToEdit] = useState<IPrivateService | null>(null);
    const [serviceToDelete, setServiceToDelete] = useState<IPrivateService | null>(null);
    const notify = useNotify();
    const form = useForm<FormData>({ defaultValues: getServiceDefaultValues(serviceToEdit) });

    const handleSave: SubmitHandler<FormData> = async (data) => {
        try {
            const url = serviceToEdit ? `${constants.API_URL}/instructor/private_services` : `${constants.API_URL}/instructor/private_services/`;
            const method = serviceToEdit ? axios.put : axios.post;

            await method(url, getRequestPayload(data, serviceToEdit, expert_id));
            notify('נשמר בהצלחה', { type: 'success' });
            refetch();
            setIsDialogOpen(false);
        } catch (error) {
            handleRequestError(error, notify);
        }
    };

    const handleDelete = async () => {
        if (serviceToDelete) {
            try {
                await axios.delete(`${constants.API_URL}/instructor/private_services/${serviceToDelete._id}`);
                notify('השירות נמחק בהצלחה', { type: 'success' });
                refetch();
                setIsDeleteConfirmationOpen(false);
            } catch (error) {
                handleRequestError(error, notify);
            }
        }
    };

    const handleOpenDialog = (service: IPrivateService | null) => {
        setServiceToEdit(service);
        form.reset(getServiceDefaultValues(service));
        setIsDialogOpen(true);
    };

    const handleOpenDeleteConfirmation = (service: IPrivateService) => {
        setServiceToDelete(service);
        setIsDeleteConfirmationOpen(true);
    };

    if (!expert_id) return null;

    return (
        <>
            <ServiceDialog
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                form={form}
                handleSave={() => {
                    form.handleSubmit(handleSave)();
                }}
            />
            <DeleteConfirmationDialog
                serviceToDelete={serviceToDelete}
                isOpen={isDeleteConfirmationOpen}
                onClose={() => setIsDeleteConfirmationOpen(false)}
                onConfirm={handleDelete}
            />
            <ServiceList privateServices={privateServices} openDialog={handleOpenDialog} openDeleteConfirmation={handleOpenDeleteConfirmation} refetch={refetch} />
        </>
    );
};

const getServiceDefaultValues = (service: IPrivateService | null): FormData => ({
    title: service?.title || { he: '', en: '' },
    description: service?.description || { he: '', en: '' },
    duration: service?.duration || 0,
    price: service?.price || 0,
    price_before_discount: service?.price_before_discount || 0,
    gap_duration: service?.gap_duration || 0,
    public: service?.public || false,
    type: service?.type || '1TO1',
});

const getRequestPayload = (data: FormData, serviceToEdit: IPrivateService | null, expert_id: string) => ({
    ...data,
    id: serviceToEdit?._id,
    instructor_id: expert_id,
    public: data.type === '1TO1' ? data.public : false,
});

const handleRequestError = (error: any, notify: any) => {
    if (error instanceof AxiosError) {
        notify(getLocalizedErrorMessage(error, 'he') || 'משהו השתבש', { type: 'error' });
    } else {
        notify('משהו השתבש', { type: 'error' });
    }
};

const ServiceDialog = ({ isOpen, onClose, form, handleSave }: { isOpen: boolean; onClose: () => void; form: UseFormReturn<FormData>; handleSave: () => void }) => (
    <Dialog maxWidth='md' fullWidth PaperProps={{ style: { borderRadius: 20 } }} open={isOpen} onClose={onClose}>
        <div className='p-8 pt-4' dir='rtl'>
            <h1 className='text-center font-medium text-lg'>עריכת שירות</h1>
            <ServiceForm form={form} />
            <div className='mt-8 flex justify-between'>
                <Button variant='text' color='error' onClick={() => form.reset()}>
                    ביטול
                </Button>
                <Button variant='contained' onClick={handleSave}>
                    שמירה
                </Button>
            </div>
        </div>
    </Dialog>
);

type DeleteConfirmationDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    serviceToDelete: IPrivateService | null;
};

const DeleteConfirmationDialog = ({ isOpen, onClose, onConfirm, serviceToDelete }: DeleteConfirmationDialogProps) => (
    <Dialog maxWidth='xs' fullWidth open={isOpen} onClose={onClose} dir='rtl'>
        <div className='p-4 pt-2' dir='rtl'>
            <h2 className='text-lg'>
                {`מחיקת השירות `}
                <strong>{serviceToDelete?.title.he}</strong>
            </h2>
            <div className='border-b border-gray-200' />
            <p className='pt-4 text-sm'>פעולה זו בלתי הפיכה</p>
            <div className='mt-4 flex justify-between' dir='ltr'>
                <Button variant='contained' color='error' onClick={onConfirm}>
                    מחק
                </Button>
                <Button variant='text' onClick={onClose}>
                    ביטול
                </Button>
            </div>
        </div>
    </Dialog>
);

const ServiceForm = ({ form }: { form: UseFormReturn<FormData> }) => (
    <div className='gap-2 mt-4 flex flex-col'>
        <ServiceTypeSelector form={form} />
        <TextInput label='כותרת עברית' name='title.he' limit={TITLE_LIMIT} form={form} />
        <TextInput label='כותרת אנגלית' name='title.en' limit={TITLE_LIMIT} form={form} />
        <TextInput label='תיאור עברית' name='description.he' limit={DESCRIPTION_LIMIT} form={form} multiline />
        <TextInput label='תיאור אנגלית' name='description.en' limit={DESCRIPTION_LIMIT} form={form} multiline />
        <ServiceDetails form={form} />
    </div>
);

const ServiceTypeSelector = ({ form }: { form: UseFormReturn<FormData> }) => (
    <div className='flex flex-row gap-2'>
        <Controller
            control={form.control}
            name='type'
            render={({ field }) => (
                <Select {...field} className='self-center px-2 text-right w-56'>
                    {Object.entries(TypesNames).map(([key, value]) => (
                        <MenuItem value={key} key={key}>
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            )}
        />
        {form.watch('type') === '1TO1' && (
            <Controller control={form.control} name='public' render={({ field }) => <FormControlLabel label='ציבורי?' control={<Checkbox {...field} checked={field.value} />} />} />
        )}
    </div>
);

type TextInputProps = {
    label: string;
    name: FieldPath<FormData>;
    limit: number;
    form: UseFormReturn<FormData>;
    multiline?: boolean;
};

const TextInput = ({ label, name, limit, form, multiline = false }: TextInputProps) => (
    <TextField
        label={label}
        variant='standard'
        fullWidth
        inputProps={{ maxLength: limit }}
        {...form.register(name, { required: true })}
        helperText={`${(form.watch(name) as string)?.length || 0}/${limit}`}
        multiline={multiline}
    />
);

const ServiceDetails = ({ form }: { form: UseFormReturn<FormData> }) => (
    <div className='flex gap-4 mt-8'>
        <TextField type='number' label='משך השירות' variant='standard' fullWidth {...form.register('duration', { valueAsNumber: true })} />
        <TextField type='number' label='הפסקה לאחר מפגש' variant='standard' fullWidth {...form.register('gap_duration', { valueAsNumber: true })} />
        <TextField type='number' label='מחיר' variant='standard' fullWidth {...form.register('price', { required: true, valueAsNumber: true })} />
        <TextField type='number' label='מחיר לפני הנחה' variant='standard' fullWidth {...form.register('price_before_discount', { valueAsNumber: true })} />
    </div>
);

const ServiceList = ({
    privateServices,
    openDialog,
    openDeleteConfirmation,
    refetch,
}: {
    privateServices: IPrivateService[];
    openDialog: (service: IPrivateService | null) => void;
    openDeleteConfirmation: (service: IPrivateService) => void;
    refetch: () => void;
}) => (
    <div className='flex flex-col'>
        {privateServices
            .sort((a: IPrivateService, b: IPrivateService) => a.title.he.localeCompare(b.title.he))
            ?.map((service: IPrivateService, index: number) => (
                <PrivateServiceItem
                    service={service}
                    key={`private-session-row#${index}`}
                    refetch={refetch}
                    openDialog={openDialog}
                    openDeleteConfirmation={openDeleteConfirmation}
                />
            ))}
        <AddNewServiceButton onClick={() => openDialog(null)} />
    </div>
);

const AddNewServiceButton = ({ onClick }: { onClick: () => void }) => (
    <button
        onClick={(e) => {
            e.preventDefault();
            onClick();
        }}>
        <div className='w-full border-b py-2 px-4 flex flex-row justify-between h-[61px] hover:bg-gradient-to-l from-gray-100 to-white transition-colors'>
            <span className='text-start'>+ הוספת שירות חדש</span>
        </div>
    </button>
);

export default PrivateServicesTable;
