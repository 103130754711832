import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import {
	ArrayInput,
	BooleanInput,
	Edit,
	ImageField,
	ImageInput,
	NumberInput,
	ReferenceArrayInput,
	SelectArrayInput,
	SimpleForm,
	SimpleFormIterator,
	TextInput,
} from 'react-admin';
import { Personalization } from '../Meeting/MeetingCreate';

const CategoryEdit = () => {
	const [ignoreAge, setIgnoreAge] = useState(false);
	const transform = (data: any) => ({
		...data,
		age: ignoreAge ? { to: null, from: null } : data.age,
	});
	return (
		<Edit transform={transform}>
			<SimpleForm>
				<Grid spacing={2} container>
					<Grid item sm={4}>
						<TextInput source='hebrew_name' fullWidth />
					</Grid>
					<Grid item sm={4}>
						<TextInput source='english_name' fullWidth />
					</Grid>
					<Grid item sm={6}>
						<ReferenceArrayInput perPage={999} source='subCategories' reference='instructor-category/all'>
							<SelectArrayInput optionText={'hebrew_name'} />
						</ReferenceArrayInput>
					</Grid>
					<Grid item sm={2}>
						<BooleanInput source='active' label='הצג למשתמשים' />
					</Grid>
					<Grid item sm={2}>
						<BooleanInput source='public' label='הצג ברשימת מומחים (ציבורי)' defaultValue={true} />
					</Grid>
					<Box width='100%' />
					<Grid item sm={2}>
						<NumberInput source='order' label='סדר' />
					</Grid>
					<Grid item sm={2}>
						<NumberInput source='newborn_order' label='סדר מנוי לידה' />
					</Grid>

					<Box width={'100%'} />
					<Grid item sm={6}>
						<TextInput source='description_lng.he' label='תיאור עברית' fullWidth multiline />
					</Grid>
					<Grid item sm={6}>
						<TextInput source='description_lng.en' label='תיאור אנגלית' fullWidth multiline />
					</Grid>

					<Box width={'100%'} />
					<Grid item sm={12}>
						<ImageInput source='image'>
							<ImageField source='src' />
						</ImageInput>
					</Grid>
					<Grid item sm={8}>
						<TextInput source='link' fullWidth />
					</Grid>
					<Personalization setIgnoreAge={setIgnoreAge} ignoreAge={ignoreAge} />

					<Grid item sm={12}>
						<ArrayInput source='info_bullets' fullWidth>
							<SimpleFormIterator fullWidth inline>
								<TextInput source='he' style={{ flex: 1 }} />
								<TextInput source='en' style={{ flex: 1 }} />
							</SimpleFormIterator>
						</ArrayInput>
					</Grid>
				</Grid>
			</SimpleForm>
		</Edit>
	);
};
export default CategoryEdit;
