import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import constants from '../constants';
import useFetch from '../hooks/useFetch';

const MEMBER_STATS_URL = `${constants.API_URL}/admin/member-stats`;
const DATING_STATS_URL = `${constants.API_URL}/admin/dating-stats`;
const EXPERT_STATS_URL = `${constants.API_URL}/admin/expert-stats`;
const HANDLE_COMPANY_CHANGE_URL = `${constants.API_URL}/member/change_company`;
const ACTIVE_NEWBORN_URL = `${constants.API_URL}member/active-newborn`;
const GROUP_SESSIONS_STATS_URL = `${constants.API_URL}/admin/group-sessions-stats`;
const NEWBORN_INVITATIONS_STATS_URL = `${constants.API_URL}/invitation/stats`;
const PRIVATE_SESSIONS_STATS_URL = `${constants.API_URL}/instructor/private_services/sessions/stats`;
const PASS_IT_ON_STATS_URL = `${constants.API_URL}passiton/stats`;
const PASS_IT_ON_RANDOM_URL = `${constants.API_URL}passiton/random`;
const PASS_IT_ON_DIFF_URL = `${constants.API_URL}passiton/diff`;
const NPS_URL = `${constants.API_URL}/admin/nps`;
type ExpertStatsParams = {
    total: number;
    registeredExperts: number;
    synchedExperts: number;
    safeSpaceExperts: number;
    experts: any[];
};

type MemberStats = {
    newUsers: { web: number; mobile: number };
    todayUsers: { web: number; mobile: number };
    totalUsers: number;
    hourlyLoginsBetweenTwoDays: { hour: Date; day1Count: number; day2Count: number }[];
};
type PassItOnCompanyStats = { stats: { companyName: string; change: number; currentCount: number; yesterdayCount: number }[]; totalChange: number; totalCount: number };
type RandomPassItOnMessage = { company: string; text: number };
type NewbornInvitiationCount = { count: number };
type GroupSessionsStats = { todaysSessions: any[]; tomorrowSessions: any[]; todaysEnrollments: number };

const useDashboard = () => {
    const [expertId, setExpertId] = useState('');
    const [company, setCompany] = useState('');
    const notify = useNotify();
    const memberStats = useFetch<MemberStats>(MEMBER_STATS_URL);
    const dating = useFetch<string>(DATING_STATS_URL);
    const expertStats = useFetch<ExpertStatsParams>(EXPERT_STATS_URL);
    const activeNewborns = useFetch<{ email: string }[]>(ACTIVE_NEWBORN_URL);
    const groupSessionsStats = useFetch<GroupSessionsStats>(GROUP_SESSIONS_STATS_URL);
    const newbornSentCount = useFetch<NewbornInvitiationCount>(NEWBORN_INVITATIONS_STATS_URL, {
        params: { filter: { type: { $in: ['newborn_1m', 'newborn_3m', 'newborn_6m'] } } },
    });
    const totalPrivateSessions = useFetch<{ data: number }>(PRIVATE_SESSIONS_STATS_URL);
    const totalPrivateSessionsToday = useFetch<{ data: number }>(PRIVATE_SESSIONS_STATS_URL, {
        params: { filter: { createdAt: { $gt: moment().startOf('day'), $lt: moment().endOf('day') } } },
    });
    const passItOnStats = useFetch<PassItOnCompanyStats>(PASS_IT_ON_STATS_URL);
    const passItOnDiff = useFetch<{ diff: number }>(PASS_IT_ON_DIFF_URL, { params: { date: moment().subtract(1, 'days').toISOString() } });
    const randomPassItOnStats = useFetch<RandomPassItOnMessage[]>(PASS_IT_ON_RANDOM_URL, { params: { limit: 3 } });
    const nps = useFetch<{ NPS: number }>(NPS_URL);
    const handleChangeMyCompany = async (company: string) => {
        setCompany(company);
        await axios.post(HANDLE_COMPANY_CHANGE_URL, { company });
        localStorage.setItem('company', company);
    };
    const handleChangeMyExpert = async (expert_id: string) => {
        const expert = expert_id === 'null' ? null : expert_id;
        setExpertId(expert ?? '');
        await axios.post(HANDLE_COMPANY_CHANGE_URL, { expert_profile: expert });
        if (expert) localStorage.setItem('expertId', expert);
        else localStorage.removeItem('expertId');
    };

    useEffect(() => {
        const _expert = localStorage.getItem('expertId');
        if (_expert) setExpertId(_expert);
        const _company = localStorage.getItem('company');
        if (_company) setCompany(_company);
    }, [expertStats.data?.experts]);

    const handleCopyNoWixEmails = async () => {
        try {
            const response = await axios.get(`${constants.API_URL}admin/company-newsletter-emails`);
            navigator.clipboard.writeText(response.data);
            notify('הנה תותי, מיילים (בלי וויקס ו-MyHeritage) הועתקו. בי', { type: 'success' });
        } catch (error) {}
    };
    const handleCopyNewbornEmails = async () => {
        try {
            navigator.clipboard.writeText(
                activeNewborns.data
                    ?.filter((x) => x.email)
                    .map((x: any) => x.email)
                    .join('\n') || ''
            );
            notify('מיילים הועתקו ללוח. בי', { type: 'success' });
        } catch (error) {}
    };

    return {
        handleChangeMyCompany,
        handleChangeMyExpert,
        handleCopyNewbornEmails,
        handleCopyNoWixEmails,
        groupSessionsStats,
        activeNewborns,
        company,
        newbornSentCount,
        expertStats,
        expertId,
        randomPassItOnStats,
        passItOnStats,
        totalPrivateSessions,
        totalPrivateSessionsToday,
        memberStats,
        dating,
        passItOnDiff,
        nps: nps.data?.NPS,
    };
};

export default useDashboard;
