export enum PaymentProductType {
    NEWBORN_1M = 'newborn_1m',
    NEWBORN_3M = 'newborn_3m',
    NEWBORN_6M = 'newborn_6m',
    GROUP_SESSION = 'group_session',
    PRIVATE_SESSION_GUEST = 'private_session_guest',
    PRIVATE_SESSION_MEMBER = 'private_session_member',
}

export const productTitles: Record<PaymentProductType, string> = {
    group_session: 'הרצאה/סדנא (מפגש קבוצתי)',
    newborn_1m: 'מנוי לידה 1 חודשים',
    newborn_3m: 'מנוי לידה 3 חודשים',
    newborn_6m: 'מנוי לידה 6 חודשים',
    private_session_guest: 'מפגש פרטני - אורחים',
    private_session_member: 'מפגש פרטני - מנויים',
};
