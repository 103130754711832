import { Resource, ResourceProps } from 'react-admin';
import { BiAward, BiSolidCoupon, BiStats, BiSupport } from 'react-icons/bi';
import { CgOrganisation } from 'react-icons/cg';
import {
    FaBabyCarriage,
    FaCalendar,
    FaCog,
    FaFlask,
    FaHandshake,
    FaHeadphones,
    FaLayerGroup,
    FaMicrophone,
    FaMicrophoneAlt,
    FaNewspaper,
    FaTag,
    FaUsers,
    FaVideo,
} from 'react-icons/fa';
import { GiWeightLiftingUp } from 'react-icons/gi';
import { MdOutlineVideoLibrary, MdSchool } from 'react-icons/md';
import './App.css';
import CompanyCreate from './components/Company/CompanyCreate';
import CompanyEdit from './components/Company/CompanyEdit';
import CompanyList from './components/Company/CompanyList';
import ExpertEdit from './components/Expert/ExpertEdit';
import ExpertList from './components/Expert/ExpertList';
import MeetingCreate from './components/Meeting/MeetingCreate';
import MeetingEdit from './components/Meeting/MeetingEdit';
import MeetingList from './components/Meeting/MeetingList';
import ServiceEdit from './components/Service/ServiceEdit';
import ServiceList from './components/Service/ServiceList';

import ArticleCreate from './components/Article/ArticleCreate';
import ArticleEdit from './components/Article/ArticleEdit';
import ListArticles from './components/Article/ListArticles';
import CategoryCreate from './components/Category/CategoryCreate';
import CategoryEdit from './components/Category/CategoryEdit';
import CategoryList from './components/Category/CategoryList';
import ExpertCreate from './components/Expert/ExpertCreate';
import CreateNewbornInvitation from './components/Invitation/CreateNewbornInvitation';
import InvitationsList from './components/Invitation/InvitationsList';
import LectureCategoryCreate from './components/Lecture/LectureCategory/LectureCategoryCreate';
import LectureCategoryEdit from './components/Lecture/LectureCategory/LectureCategoryEdit';
import LectureCategoryList from './components/Lecture/LectureCategory/LectureCategoryList';
import LectureCreate from './components/Lecture/LectureCreate';
import LectureEdit from './components/Lecture/LectureEdit';
import LectureList from './components/Lecture/LectureList';
import MediaCategoryCreate from './components/MediaCategory/MediaCategoryCreate';
import MediaCategoryEdit from './components/MediaCategory/MediaCategoryEdit';
import MediaCategoryList from './components/MediaCategory/MediaCategoryList';
import MediaLinkCreate from './components/MediaLink/MediaLinkCreate';
import MediaLinkEdit from './components/MediaLink/MediaLinkEdit';
import MediaLinkList from './components/MediaLink/MediaLinkList';
import MeetingShow from './components/Meeting/MeetingShow';
import MemberEdit from './components/Member/MemberEdit';
import MemberList from './components/Member/MemberList';
import MemberShow from './components/Member/MemberShow';
import PrivateSessionsList from './components/PrivateSessions/PrivateSessionsList';
import ReviewCreate from './components/Review/ReviewCreate';
import ReviewEdit from './components/Review/ReviewEdit';
import ReviewList from './components/Review/ReviewList';
import SectionEdit from './components/Section/SectionEdit';
import SectionList from './components/Section/SectionList';
import ServiceCreate from './components/Service/ServiceCreate';
import TagCreate from './components/Tag/TagCreate';
import TagEdit from './components/Tag/TagEdit';
import TagList from './components/Tag/TagList';
import VodCreate from './components/Vod/VodCreate';
import VodEdit from './components/Vod/VodEdit';
import VodList from './components/Vod/VodList';
import { ROLE } from './providers/auth.types';

import CompanyShow from './components/Company/CompanyShow';
import CouponCreate from './components/Coupon/CouponCreate';
import CouponEdit from './components/Coupon/CouponEdit';
import CouponList from './components/Coupon/CouponList';
import CreatePrivateSession from './components/PrivateSessions/Dialogs/CreateSessionDialog';
import MonthlySessionEdit from './components/PrivateSessions/MonthlySessions/MonthlySessionsEdit';
import MonthlySessionsStats from './components/PrivateSessions/MonthlySessions/MonthlySessionsStats';
import PrizeCreate from './components/Prize/PrizeCreate';
import PrizeEdit from './components/Prize/PrizeEdit';
import PrizeList from './components/Prize/PrizeList';
import ProactiveItemCreate from './components/Proactive/ProactiveItemCreate';
import ProactiveItemEdit from './components/Proactive/ProactiveItemEdit';
import ProactiveItemList from './components/Proactive/ProactiveItemList';
import SettingCreate from './components/Setting/SettingCreate';
import SettingEdit from './components/Setting/SettingEdit';
import SettingList from './components/Setting/SettingList';
import TrialUsersCreate from './components/Setting/TrialUsers/TrialUsersCreate';
import TrialUsersList from './components/Setting/TrialUsers/TrialUsersList';
import CouponShow from './components/Coupon/CouponShow';
const Resources = (permissions: any) => {
    const resources: {
        label: string;
        name: string;
        icon?: ResourceProps['icon'];
        list?: ResourceProps['list'];
        create?: ResourceProps['create'];
        edit?: ResourceProps['edit'];
        show?: ResourceProps['show'];
        onlyAvailableTo?: ROLE[];
    }[] = [
        {
            label: 'חברות',
            icon: CgOrganisation,
            name: 'company',
            list: CompanyList,
            show: CompanyShow,
            create: CompanyCreate,
            edit: ['superadmin', 'business-admin', 'admin'].includes(permissions) ? CompanyEdit : undefined,
            onlyAvailableTo: ['business-admin', 'admin', 'superadmin'],
        },
        {
            label: 'קטגוריות VOD',
            icon: MdOutlineVideoLibrary,
            name: 'vod/categories',
            list: MediaCategoryList,
            edit: MediaCategoryEdit,
            create: MediaCategoryCreate,
            onlyAvailableTo: ['editor', 'business-admin', 'admin', 'superadmin'],
        },
        {
            label: 'משובים',
            name: 'review',
            icon: () => <FaLayerGroup />,
            list: ReviewList,
            edit: ReviewEdit,
            create: ReviewCreate,
            onlyAvailableTo: ['admin', 'business-admin', 'superadmin'],
        },

        {
            label: 'מומחים',
            icon: BiSupport,
            name: 'instructor',
            list: ExpertList,
            edit: ExpertEdit,
            create: ExpertCreate,
            onlyAvailableTo: ['admin', 'editor', 'superadmin'],
        },
        {
            label: 'קטגוריות מומחים',
            icon: FaLayerGroup,
            name: 'instructor-category',
            list: CategoryList,
            edit: CategoryEdit,
            create: CategoryCreate,
            onlyAvailableTo: ['admin', 'editor', 'superadmin'],
        },
        {
            label: 'שירותים',
            icon: MdSchool,
            name: 'service',
            list: ServiceList,
            edit: ServiceEdit,
            create: ServiceCreate,
            onlyAvailableTo: ['admin', 'editor', 'superadmin'],
        },
        {
            label: 'הרצאות וסדנאות',
            icon: FaCalendar,
            name: 'meeting',
            list: MeetingList,
            edit: MeetingEdit,
            create: MeetingCreate,
            show: MeetingShow,
            onlyAvailableTo: ['admin', 'editor', 'superadmin', 'group-sessions-viewer', 'business-admin'],
        },
        {
            label: 'VOD',
            icon: FaVideo,
            name: 'vod',
            list: VodList,
            edit: VodEdit,
            create: VodCreate,
            onlyAvailableTo: ['admin', 'editor', 'superadmin'],
        },
        {
            label: 'כתבות',
            icon: FaNewspaper,
            name: 'article',
            list: ListArticles,
            edit: ArticleEdit,
            create: ArticleCreate,
            onlyAvailableTo: ['admin', 'editor', 'superadmin'],
        },
        {
            label: 'פודקסטים ועוד',
            icon: FaHeadphones,
            name: 'media-links',
            list: MediaLinkList,
            edit: MediaLinkEdit,
            create: MediaLinkCreate,
            onlyAvailableTo: ['admin', 'editor', 'superadmin'],
        },
        {
            label: 'הרצאות',
            icon: FaMicrophone,
            name: 'lecture',
            list: LectureList,
            edit: LectureEdit,
            create: LectureCreate,
            onlyAvailableTo: ['admin', 'editor', 'superadmin', 'business-admin'],
        },
        {
            label: 'קטגוריות הרצאות',
            icon: FaMicrophoneAlt,
            name: 'lecture/category',
            list: LectureCategoryList,
            edit: LectureCategoryEdit,
            create: LectureCategoryCreate,
            onlyAvailableTo: ['admin', 'editor', 'superadmin', 'business-admin'],
        },
        {
            label: 'בוסטים',
            icon: GiWeightLiftingUp,
            name: 'proactive/item',
            list: ProactiveItemList,
            create: ProactiveItemCreate,
            edit: ProactiveItemEdit,
            onlyAvailableTo: ['admin', 'editor', 'superadmin', 'business-admin'],
        },
        {
            label: 'פרסים',
            icon: BiAward,
            name: 'prize',
            list: PrizeList,
            create: PrizeCreate,
            edit: PrizeEdit,
            onlyAvailableTo: ['admin', 'editor', 'superadmin', 'business-admin'],
        },
        {
            label: 'תחומי עניין',
            icon: FaTag,
            name: 'tag/all',
            list: TagList,
            create: TagCreate,
            edit: TagEdit,
            onlyAvailableTo: ['admin', 'editor', 'superadmin'],
        },
        {
            label: 'קופונים',
            icon: BiSolidCoupon,
            name: 'coupon',
            list: CouponList,
            create: CouponCreate,
            edit: CouponEdit,
            show: CouponShow,
            onlyAvailableTo: ['admin', 'editor', 'superadmin'],
        },
        {
            label: 'מנויי לידה',
            name: 'invitation',
            icon: () => <FaBabyCarriage color='#D6ACAC' />,
            list: InvitationsList,
            create: CreateNewbornInvitation,
            onlyAvailableTo: ['admin', 'editor', 'superadmin'],
        },
        {
            label: 'מתחמים',
            name: 'section',
            icon: FaLayerGroup,
            list: SectionList,
            edit: SectionEdit,
            onlyAvailableTo: ['admin', 'editor', 'superadmin'],
        },

        {
            label: 'משתמשים',
            icon: FaUsers,
            name: 'member',
            list: MemberList,
            edit: permissions === 'superadmin' ? MemberEdit : undefined,
            show: MemberShow,
            onlyAvailableTo: ['superadmin', 'admin', 'editor'],
        },
        {
            label: 'מפגשים פרטניים',
            icon: FaHandshake,
            name: 'instructor/private_services/sessions',
            list: PrivateSessionsList,
            create: CreatePrivateSession,
            onlyAvailableTo: ['superadmin', 'admin', 'editor'],
        },
        {
            label: 'סיכום מפגשי מומחים',
            icon: BiStats,
            name: 'instructor/sessions/stats/by_host',
            list: MonthlySessionsStats,
            edit: MonthlySessionEdit,
            onlyAvailableTo: ['admin', 'superadmin'],
        },
        {
            label: 'הגדרות אפליקציה',
            icon: FaCog,
            name: 'setting',
            list: SettingList,
            create: SettingCreate,
            edit: SettingEdit,
            onlyAvailableTo: ['superadmin'],
        },
        { label: 'מנוי התנסות', icon: FaFlask, name: 'setting/trial', list: TrialUsersList, create: TrialUsersCreate, onlyAvailableTo: ['admin', 'superadmin'] },
    ];

    return resources
        .filter((resource) => !resource.onlyAvailableTo || resource.onlyAvailableTo.includes(permissions))
        .map((resource) => <Resource key={resource.name} {...resource} options={{ label: resource?.label }} />);
};

export default Resources;
